.eventbtn {
  position: relative;
  display: flex;
  background: linear-gradient(-90deg, #00ccb4, #9900fc);
  background-size: 70em 10em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2vh;
  border: none;
  border-radius: 0.25em;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
  animation: Gradient 4s ease infinite;
  text-decoration: none;
  cursor: pointer;
  max-width: 18em;
}

.eventbtn div {
  color: black;
  padding: 1.5em 1.55em;
  border-radius: inherit;
  background-size: inherit;
  background-image: inherit;
  animation: Gradient 4s ease infinite;
  position: relative;
  z-index: 1;
  mix-blend-mode: overlay;
  font-family: "Press Start 2P";
  text-align: center;
}

@keyframes Gradient {
  50% {
    background-position: 200% 50%;
    transform: skew(-3deg);
  }
}

.eventbtn::after {
  content: "";
  position: absolute;
  background-size: inherit;
  background-image: inherit;
  animation: inherit;
  left: 0px;
  right: 0px;
  top: 2px;
  height: 100%;
  filter: blur(1rem);
}
