.fade-out {
  opacity: 0;
  filter: blur(0.4rem);
  transition: all 0.5s ease-in-out;
}

@media (prefers-reduced-motion) {
  .fade-out {
    transition: none;
  }
}

.fade-in {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}
