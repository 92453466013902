.flyer-text {
  font-family: "Press Start 2P";
  font-size: 1rem;
}

.flyer-header {
  font-family: "Press Start 2P";
  font-size: 5rem;
  text-align: center;
  background: linear-gradient(-90deg, #00ccb4, #9900fc);
  position: relative;
  background-size: 70em 10em;
  text-transform: uppercase;
  border: none;
  border-radius: 0.05em;
  letter-spacing: 1px;
  padding: 0;
  margin: 0;
  animation: HeaderGradient 10s ease infinite;
  text-decoration: none;
}

.flyer-header div {
  color: black;
  padding: 0.3rem 0.2em;
  border-radius: inherit;
  background-size: inherit;
  background-image: inherit;
  animation: HeaderGradient 10s ease infinite;
  position: relative;
  z-index: 1;
  mix-blend-mode: overlay;
  font-family: "Press Start 2P";
  text-align: center;
  font-size: 12vh;
}

.flyer-header::after {
  content: "";
  position: absolute;
  background-size: inherit;
  background-image: inherit;
  animation: inherit;
  left: 0px;
  right: 0px;
  top: 2px;
  height: 100%;
  filter: blur(1rem);
}

@keyframes HeaderGradient {
  50% {
    background-position: 200% 50%;
    transform: skew(-1deg);
  }
}

.fade-out-info-item {
  opacity: 0;
  transform: translateX(25px);
  transition: all 0.5s ease-in-out;
}

@media (prefers-reduced-motion) {
  .fade-out {
    transition: none;
  }
}

.fade-in-info-item {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}
